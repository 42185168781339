<template>
  <div>
    <section class="content box-management">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">

            <div class="col-xs-12">
              <h2>Ustawienia przypisywania kategorii zleceń do ról</h2>
            </div>
            <ul class="box-body row">
              <transition-group name="fade">
                <li
                  v-for="(role) in roles"
                  :key="role.id"
                  class="list-group-item col-xs-6"
                >
                  <div class="row">
                    <div class="col-sm-4">
                      <label :for="`client-select${role.id}`">{{ role.label
                        }}</label>
                    </div>
                    <div
                      v-if="roles"
                      class="col-sm-8"
                    >
                      <select
                        :id="`client-select${role.id}`"
                        v-model="rolesList[role.name]"
                        class="form-control"
                        multiple
                      >
                        <option
                          v-for="(option, optIndex) in taskCategories"
                          :key="optIndex"
                          :value="option.id"
                        >
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
              </transition-group>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import api from '../../../../../api'

  export default {
    components: {
    },
    mixins: [],
    data () {
      return {
        events: null,
        service: this.$route.meta.acl.service,
        gridKey: 0,
        search: '',
        schema: [
          {
            name: 'search',
            label: 'Szukaj użytkownika',
            rules: { required: false }
          }],
        roles: [],
        taskCategories: [],
        rolesFilters: []
      }
    },
    computed: {
      rolesList () {
        let rolesListObj = {}
         this.roles.map(role => {
          let roleObj = {}
          roleObj[role.name] = this.taskCategories.filter(taskCategory => taskCategory.roles.find(catRole => catRole.id === role.id)).map(cat => cat.id)
          return roleObj
        }).forEach(role => {
            let [key, value] = Object.entries(role)[0]
            rolesListObj[key] = value
        })
        return rolesListObj
      }
    },
    beforeMount () {
      this.getTaskCategories()
      this.getRoles()
    },
    created () {
      this.getInitEvents()
    },
    mounted () {
      this.$events.on(this.events.save, this.save)
      // this.getUsers()
    },
    methods: {
      getInitEvents () {
        this.events = {
          save: `${this.service}:taskCategoriesRoles:save`
        }
      },
      async save () {
        try {

          await api.request(this.service, 'put', '/task-categories', this.rolesList)
          this.$notify({
            type: 'success',
            title: 'Zapisano',
            text: ''
          })
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można zapisać'
          })
        }
      },
      async getTaskCategories () {
        try {
          let response = await api.request(this.service, 'get', '/task-categories')
          this.taskCategories = response.data
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać kategorii zleceń'
          })
        }
      },
      async getRoles () {
        try {
          let response = await api.request(this.service, 'get', '/roles')
          this.roles = response.data
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać ról'
          })
        }
      },
    }
  }
</script>
<style>
  .client-roles-filter {
    padding: 20px;
  }

  .clients-client-checkbox-container {
    list-style: none;
    padding: 5px;
  }

  .fade-enter-active {
    animation: stretch 0.25s;
  }

  .fade-leave-active {
    animation: stretch 0.25s reverse;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    height: 1%;
  }

  @keyframes stretch {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
</style>
